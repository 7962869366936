const en = {
  common: {
    senseInsights: 'Next Sense Insights',
    cancel: 'Cancel',
    ok: 'OK',
    back: 'Back',
    darkMode: 'Switch to dark mode',
    lightMode: 'Switch to light mode',
    comingSoon: 'Coming soon',
    welcome: 'Welcome',
    loading: 'Loading...',
    mobileInfo: 'This application supports only desktop version',
    desktopInfo: 'This application supports only mobile version',
    somethingWentWrong: 'Something went wrong, please try again later',
    tenant: 'Tenant',
    building: 'Building',
    noChartAvailable: 'There is no chart available.',
    minimum: 'Minimum',
    maximum: 'Maximum',
    average: 'Average',
    error429: 'Cannot retrieve your information, try again later',
    user: 'User',
    contact: 'Contact Next Sense',
    contactUs: 'Contact Us',
    organisation: 'Organisation',
    project: 'Building',
    floor: 'Floor',
    room: 'Room',
    sensor: 'Sensor',
    sendEmail: 'Send E-mail',
    send: 'Send',
    to: 'to',
    resend: 'Resend',
    continue: 'Continue',
    above: 'Above',
    below: 'Below',
    showDetails: 'Show Details',
    hideDetails: 'Hide Details',
    save: 'Save',
    auto: 'Auto',
  },
  navigation: {
    insights: 'Insights',
    explorer: 'Explore',
    reports: 'Reports',
    service: 'Service',
    configure: 'Configure',
    settings: 'Settings',
    profile: 'Profile',
    perfAudit: 'Perf. Audit',
    control: 'Controls',
  },
  insights: {
    pageTitle: 'Insights',
  },
  explorer: {
    pageTitle: 'Explore',
  },
  reports: {
    pageTitle: 'Reports',
  },
  service: {
    pageTitle: 'Service',
    emailSubject: 'Support request from {{selectedProject}}',
    contactUsText: 'If you have any questions or feedback, please reach out to us',
  },
  configure: {
    pageTitle: 'Configure',
    actuators: {
      moveAllBlindsUp: 'Move all blinds up',
      moveBlindsUp: 'Move blinds up',
      moveAllBlindsDown: 'Move all blinds down',
      moveBlindsDown: 'Move blinds down',
      moveToAutoMode: 'Move back to auto mode',
      setWorkingHours: 'Set Working Hours',
    },
  },
  settings: {
    pageTitle: 'Settings',
  },
  profile: {
    pageTitle: 'Profile',
  },
  notFound: {
    pageTitle: 'Not Found',
    didYouMean: 'Did you mean',
    notFound: 'Page not found',
  },
  temperature: {
    tooltipDay: 'Date: {{day}}',
    tooltipIndoor: 'Indoor temperature: {{indoor}}',
    tooltipOutdoor: 'Outdoor temperature: {{outdoor}}',
    comfortThresholdInfo: 'within comfortable range',
    comfortThresholdAdditionalInfo: 'between {{min}}-{{max}} °C',
    comfortThresholdErrorMessage: 'No percentage available of rooms within temperature comfortable range',
    errorMessage: 'No graph available for temperature',
  },
  humidity: {
    comfortThresholdInfo: 'within comfortable range',
    comfortThresholdAdditionalInfo: 'between {{min}}-{{max}} %',
    comfortThresholdErrorMessage: 'No percentage available of rooms within humidity comfortable range',
    errorMessage: 'No graph available for humidity',
  },
  lux: {
    comfortThresholdInfo: 'within comfortable range',
    comfortThresholdAdditionalInfo: 'between {{min}}-{{max}} lux',
  },
  unobstructedView: {
    info: 'Unobstructed view outside',
    errorMessage: 'No percentage available for the blinds with unobstructed view',
  },
  blindAutoMode: {
    info: 'Blinds in auto mode',
    errorMessage: 'No percentage available for the blinds in Auto mode',
  },
  occupancy: {
    comfortThresholdInfo: 'average occupancy',
    comfortThresholdAdditionalInfo: 'for the last 8 hours',
    comfortThresholdErrorMessage: 'No percentage available of the occupied rooms',
    errorMessage: 'No graph available for rooms occupied',
  },
  energyBalance: {
    errorMessage: 'No graph available for energy performance',
  },
  CO2: {
    comfortThresholdInfo: 'within comfortable range',
    comfortThresholdAdditionalInfo: 'below {{max}} ppm',
    comfortThresholdErrorMessage: 'No percentage available of rooms within CO2 comfortable range',
    errorMessage: 'No graph available for CO2 Level',
  },
  weatherPrediction: {
    currentErrorMessage: 'No current weather information available',
    forecastErrorMessage: 'No weather forecast available',
  },
  performanceTarget: {
    comfortThresholdInfo: 'Performance Target',
    comfortThresholdAdditionalInfo: 'of {{max}} kWh/m2/year',
    errorMessage: 'No percentage available of the building Performance Target',
  },
  parisProof: {
    comfortThresholdInfo: 'Reduction required to achieve Paris Proof',
    comfortThresholdAdditionalInfo: 'target of 50 kWh/m2/year',
    errorMessage: 'No percentage available of the building Paris Proof achievement',
    comfortThresholdStatus: 'Better than Paris Proof',
  },
  buildingPerformance: {
    errorMessage: 'No graph available for Building Performance',
  },
  errors: {
    noLocalStorageKey: 'The key does not exist',
    noCameraProps: 'There is no camera properties',
    noDOMElement: 'There is no DOM element',
    noRequest: 'Request not found',
    unAuthorized: 'Unauthorized',
    badRequest: 'Bad request',
    forbidden: 'Incorrect Details',
    wrongResponseException: 'Something went wrong, please try again later',
    dataMissing: 'Data missing',
    dataLoading: 'Data Loading',
    chart: 'No chart available for',
  },
  auth: {
    login: 'Login',
    logout: 'logout',
    forgotPass: 'forgot password?',
    password: 'Password',
    username: 'user name',
    signIn: 'Sign In',
    getInsights: 'and get insights into your office climate',
    rememberMe: 'remember me',
    copy: 'Copyright © Next Sense {{year}}',
    passwordLength: 'Password should have at least 6 characters',
    emailPlaceholder: 'E-mail',
    otpNotificationTitle: 'Please enter your login code.',
    otpNotification: 'If you did not receive a code in your mailbox, please',
    invalidEmail: 'please fill in a correct email address',
    forgotPasswordTitle: 'Forgot Password',
    forgotPasswordText: 'No worries, we will send you reset instructions',
    createPassword: 'Create Password',
    resetPasswordTitle: 'Reset Password',
    logoutMessage: 'This action will end the session',
    loginSubText: 'Log in to access real-time readings of temperature, air quality, light, and humidity sensors around your building.',
    backToLogin: 'Back to login',
    checkYourEmail: 'Check your E-mail',
    checkYourEmailText: 'We’ve sent a password with reset link to',
    emailRecieveError: 'Didn’t receive the e-mail?',
    setNewPasswordTitle: 'Set new password',
    setNewPasswordText: 'Your new password needs to different than previously used.',
    confirmPasswordPlaceholder: 'Confirm Password',
    confirmNewPasswordTitle: 'Password Reset',
    confirmNewPasswordText: 'Password reset has been successful. Click below to login.',
  },
  makeViewer: {
    viewFit: 'View Fit',
    viewFitAll: 'View Fit All',
    showInTree: 'Show in Tree',
    hide: 'Hide',
    hideOthers: 'Hide Others',
    hideAll: 'Hide All',
    show: 'Show',
    showAll: 'Show All',
    showOthers: 'Show Others',
    xRay: 'X-Ray',
    undoXRay: 'Undo X-Ray',
    xRayOthers: 'X-Ray Others',
    resetXRay: 'Reset X-Ray',
    select: 'Select',
    undoSelect: 'Undo Select',
    clearSelection: 'Clear Selection',
    deSelect: 'Deselect',
    toggleNav: 'Toggle navigation cube',
    toggleStoreyMap: 'Toggle Storey Map',
    toggleTree: 'Toggle Tree',
    fullModelView: 'Full Model View',
    floor: 'Floor {{nth}}',
    selectStorey: 'Select storey',
    resetCamera: 'Reset camera',
    noModelError: 'Something went wrong with the model',
  },
  widgets: {
    'visual-comfort': 'Visual Comfort',
    'air-quality': 'Air Quality',
    'space-utilization': 'Space Utilization',
    'thermal-comfort': 'Thermal Comfort',
    'acoustic-comfort': 'Acoustic Comfort',
    'energy-performance': 'Energy Performance',
    'energy-balance': 'Energy Balance in kWh',
    glare: 'Glare',
    'sound-pressure-level': 'Sound Pressure Level',
    'relative-humidity': 'Relative Humidity',
    'carbon-monoxide': 'Carbon Monoxide',
    'co2-level': 'CO2 Level',
    'predicted-mean-vote': 'Predicted Mean Vote',
    'desk-occupancy': 'Desk Occupancy',
    'voc-level': 'VOC Level',
    'daylight-autonomy': 'Daylight Autonomy',
    mobility: 'Mobility',
    'indoor-temperature': 'Indoor Temperature',
    'blind-performance': 'Blind Performance',
    'actual-mean-vote': 'Actual Mean Vote',
    'particulate-matter': 'Particulate Matter',
    'meeting-room-occupancy': 'Meeting Room Occupancy',
    'light-levels': 'Light Levels',
    'air-quality-index': 'Air Quality Index',
    'outdoor-activities': 'Outdoor activities',
    'smart-window-activity': 'SmartWindow activity versus cloud coverage',
    'air-quality-desc': 'We monitor the air quality around your building. You could use this information when planning your activities for today or to keep track of it in general.',
    'outdoor-activity-desc': 'Based on today’s weather prediction we suggest what kind of activities would be best for these circumstances. Make the most of today!',
    'smart-windowactivity-desc': 'See how clouds impact the energy generation of your SmartSkin in the graph below.',
    infoButtonText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor rhoncus dolor purus non',
    'facade-illuminance': 'Facade Illuminance',
    'working-hours': 'Working Hours',
    'manual-override-all-blinds': 'Manual Override All Blinds',
    'manual-override-floor-blinds': 'Manual Override Blinds',
    'room-occupancy': 'Room Occupancy',
    'building-performance': 'Building Performance',
    'performance-target': 'Performance Target',
    'paris-proof': 'Paris Proof',
    'unobstructed-view': 'Unobstructed View',
    'blind-auto-mode': 'Blinds in auto mode',
    'comfort-threshold': 'Comfort Threshold',
    'facade-status': 'Facade Illuminance',
    'weather-predictions': 'Weather Predictions',
    'wind-alarm': 'Wind Alarm',
  },
  calendar: {
    now: 'Now (Past Hour)',
    today: 'Today',
    week: 'Past Week',
    month: 'Past Month',
    year: 'Past Year',
    cancel: 'Cancel',
    setDate: 'Set Date',
  },
  sideBar: {
    smartWindowsTitle: 'Transforming your building with',
    smartWindowsTitleHighlight: 'SmartWindows',
    smartWindowsDesc: 'We are on a mission to reduce energy consumption in buildings and you’re helping our journey! Your ‘SmartWindows’ have unique functionality: they use the sun to generate green electricity, collect and store climate data with sensors and allow you to charge your electronic devices directly from the usb output with the energy generated by your window.',
    powerBoxTitle: 'Charge your device with sunpower from',
    powerBoxTitleHighlight: 'PowerBox',
    powerBoxDesc: 'The PowerBox enables direct access to the energy generated by solar cells inside SmartWindows. Charging is possible for small electronic devices including smartphones and tablets (no laptops!), by connecting the supplied USB-c cable (<1m) to the PowerBox (only use the supplied USB-c cable for charging). A metal plate can then be used to hang the device magnetically onto the PowerBox.',
    footerText: 'Thanks for being an',
    footerSpan: 'Innovator',
  },
  projectHeader: {
    morning: 'Good Morning! We wish you a good day!',
    afternoon: 'Good Afternoon! We hope you are having a great day!',
    evening: 'Good Evening! See how your {{projectName}} performed today.',
    night: 'Good Night! Tomorrow there will be a new day of power generation.',
  },
  admin: {
    pageTitle: 'Sense Admin',
  },
  monitoring: {
    statusUp: 'Up',
    statusDown: 'Down',
    pageTitle: 'Monitoring',
  },
  commission: {
    pageTitle: 'Commission',
    controlCommission: 'Commission Control',
    scanQRLabel: 'Scan QR',
  },
  selectors: {
    noOrganisations: 'There are no organisations',
    noProjects: 'There are no projects',
    noFloors: 'There are no floors',
    noRooms: 'There are no rooms',
    noSensors: 'There are no sensors',
    floorLabel: 'Select Floor',
    roomLabel: 'Select Room',
    sensorLabel: 'Select Equipment',
    backToFloor: 'Back to floors',
    metrics: 'Select Metrics',
    hours: 'Select Hours',
    days: 'Select Days',
    scope: 'Select Scope',
    allRooms: 'All Rooms',
    allFloors: 'All Floors',
  },
  alerts: {
    success: 'Action completed successfully!',
    windAlarmAlertTitle: 'Blind controls auto-disabled',
    windAlarmAlertSubText: 'Due to high wind speeds:',
    windAlarmAlertSubTextInfo: '{{direction}}',
    windAlarmSafetyThreshold: 'Safety treshold: 30 m/s',
  },
  directions: {
    north: {
      abr: 'N',
      long: 'north',
    },
    south: {
      abr: 'S',
      long: 'south',
    },
    east: {
      abr: 'E',
      long: 'east',
    },
    west: {
      abr: 'W',
      long: 'west',
    },
    northeast: {
      abr: 'NE',
      long: 'north-east',
    },
    northwest: {
      abr: 'NW',
      long: 'north-west',
    },
    southeast: {
      abr: 'SE',
      long: 'south-east',
    },
    southwest: {
      abr: 'SW',
      long: 'south-west',
    },
  },
  weekdays: {
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday',
    sunday: 'Sunday',
  },
  perfAudit: {
    pageTitle: 'Performance Audit',
    exceedance: 'Exceedances',
    manualOverride: 'Manual Override',
    outsideThreshold: 'Time out of threshold',
    comfortThresholdInfo: 'within comfortable threshold',
    comfortThresholdAdditionalInfo: 'during',
    runAudit: 'Run Audit',
    underPerf: 'Below 60%',
    avgPerf: '60% - 90%',
    overPerf: 'Over 90%',
    timerangeSelectors: {
      pastMonth: 'Past 30 Days',
      pastWeek: 'Past 7 Days',
      yesterday: 'Yesterday',
    },
    hourSelector: {
      workingHours: 'Office Hours',
      dayHours: '24 Hours',
    },
    exceedanceIn: 'exceedance in',
    performance: 'Performance',
    roomName: 'Room Name',
    roomsAnalysed: 'Rooms analyzed',
    roomsBelow60: 'Performance < 60%',
    perfScore: 'Performance score',
  },
  controls: {
    pageTitle: 'Controls',
    blinds: 'Blinds',
    lighting: 'Lights',
    climate: 'Climate',
    wrongNetwork: {
      header: 'Error loading room controls',
      step1: 'Connect to your local Wi-Fi access point',
      step2: 'Scan the room control QR code with your camera',
      step3: 'You will automatically be connected to the Next Sense room controls app!',
    },
    loggedOut: {
      header: 'You have been logged out',
      explanation: 'Your session has expired. Please scan the room QR code to log back into your room controls.',
      action: 'Click to scan QR code',
      close: 'Close camera',
    },
    sessionTimeLeft: 'Session time left',
  },
  otpAuth: {
    sendLoginCode: 'Send Login Code',
  },
};

export default en;
